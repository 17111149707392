import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import AddToHomeScreen from "./misc/add-to-home"

library.add(fab, fas);

const Layout = ({ children }) => {

    const [ headerClass, setHeaderClass ] = React.useState("");

    React.useEffect(_ => {
        window.addEventListener('scroll', handleScroll);
        return _ => {
            window.removeEventListener('scroll', handleScroll);
        }
    });

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setHeaderClass("on-scroll");
        } else {
            setHeaderClass("");
        }
    }

    const data = useStaticQuery(graphql`
    query LayoutTitleQuery {
        site {
            siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <Header scrollClass={headerClass} siteTitle={data.site.siteMetadata.title} theme={`dark`} />
            <main className={`main-page ` + headerClass}>{children}</main>
            <Footer siteTitle={data.site.siteMetadata.title} theme={`dark`} />
            <AddToHomeScreen/>
        </>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
