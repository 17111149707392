import React from "react"
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import share from "../../images/apple-share-icon.svg"
class AddToHomeScreen extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showBanner: false,
    };
  }

  componentDidMount() {
    this.setState({
      showBanner: this.isEligible()
    });
  }

  addToHomeScreenBanner = () => {
    return (
      <div className={`banner shadow`}>
        <a href={`#`} onClick={(e) => {
          e.preventDefault();
          this.setState({
            showBanner: false
          });
        }} className={`close-button`}>&times;</a>
        <div className={`text`}>
          <FormattedMessage id="addToHomeScreen.text1" />
          <img className={'icon'} src={share} alt={``} />
          <FormattedMessage id="addToHomeScreen.text2" />
        </div>
      </div>
    )
  }

  iOSVersion = () => {
    if (this.isiPhoneOriPad()) {
      let match = (navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i),
        version = match.length ? match[0] : 'OS 1_0_0';
      let info = version.replace("_",".").replace("_","").replace("OS ","");
      return info;
    }
  }

  isiPhoneOriPad = () => {
    return (typeof navigator !== 'undefined' && navigator.userAgent.match(/ipad|iphone/i));
  }

  isEligible = () => {
    return (typeof navigator !== 'undefined' && this.isiPhoneOriPad() && this.iOSVersion() >= 16.4 && navigator.standalone === false);
  }

  render() {
    if (this.state.showBanner) {
      return (
        <div className={`add-to-home`}>{this.addToHomeScreenBanner()}</div>
      )
    } else {
      return ""
    }
  }
}

export default injectIntl(AddToHomeScreen)
